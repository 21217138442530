@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'fonts/tex/tex.css';
@import './pulse.css';

body {
    margin: 0;
}

main > .container {
  padding: 60px 15px 0;
}

.footer a {
    color: #999;
}

.footer a:hover {
    color: #fff;
}

.footer {
  background-color: #f5f5f5;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.terminal pre {
    font-family: monospace;
    padding: 0pt;
    margin: 0;
    overflow: hidden;
}

.desktop {
}

.tex {
}

.tex > div {
    display: inline-block;
}
